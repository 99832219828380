import React, { useEffect, useState } from "react";
import heroimg from "../../img/venues-part-page-img/images.png";
import {
  Autocomplete,
  Button,
  Container,
  Divider,
  InputAdornment,
  Modal,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import SearchIcon from "@mui/icons-material/Search";
import gest from "../../img/search-icon/healthicons_travel-alt.png";
import locationicon from "../../img/search-icon/location.png";
// import Economy_class from "../../img/search-icon/mdi_seat-recline-extra.png";
import "../../components/services/ServicesPages.css";
import StarRateRoundedIcon from "@mui/icons-material/StarRateRounded";
import Groups2RoundedIcon from "@mui/icons-material/Groups2Rounded";
import toplistingvenue1 from "../../img/venues-part-page-img/places-img/toplisting-img-1.png";
import toplistingvenue2 from "../../img/venues-part-page-img/places-img/toplisting-img-2.png";
import toplistingvenue3 from "../../img/venues-part-page-img/places-img/toplisting-img-3.png";
import notFound from "../../img/notFound.png";
import Pagination from "@mui/material/Pagination";
import { useNavigate } from "react-router-dom";
import useAxios from "../../useAxios";
import { useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import DialogContentText from "@mui/material/DialogContentText";
import Box from "@mui/material/Box";
import roommemebericon from "../../img/search-icon/fluent_person-24-filled.png";
import Lottie from "lottie-react";
import animationData from "../../img/lotties-files/RIGHT.json";
import { Navigation } from "swiper/modules";
import { ROUTINGDATA } from "../ROUTINGDATA";
import BottomRightForm from "./BottomRightForm";
import "./Vendorshomepage/VenueFrontpages.css";
import zIndex from "@mui/material/styles/zIndex";
import { alignProperty } from "@mui/material/styles/cssUtils";

const Venuespage = ({ dropdownPosition }) => {
  const placeholderStyle = {
    fontFamily: "Poppins-Medium",
    padding: " 5px 15px",
  };

  const navigate = useNavigate();
  const [topVenueDatas, setTopVenueDatas] = useState();
  const [searchvenues, setsearchvenues] = useState([]);
  const [bestVenueDatas, setBestVenueDatas] = useState([]);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [city, setCity] = useState([]);
  const [venueType, setVenueType] = useState([]);
  const [searchvalue, setSearchvalue] = useState("VENUES");
  const [searchflag, setSearchflag] = useState(true);
  const [venuetype, setVenuetype] = useState("");
  const [page, setPage] = useState(1);
  const [record_detials, setrecord_detials] = useState();
  const [record, setRecord] = useState();
  const itemsPerPage = 9;
  const [topListingImgs, setTopListingImgs] = useState([
    toplistingvenue1,
    toplistingvenue2,
    toplistingvenue3,
  ]);
  const [open, setOpen] = useState(false);

  const axiosData = useAxios();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  const handlePageChange = (event, value) => {
    setPage(value);
  };
  const fetchVenues = async () => {
    setLoading(true);
    try {
      const getOneAxios = await axiosData.post(`venues/venues/topListing`, {
        limit: itemsPerPage,
        page_number: page,
      });
      const data = getOneAxios?.data;
      const allData = getOneAxios;
      setTopVenueDatas(data?.data?.reverse());
      setrecord_detials(allData);
      setLoading(false);
    } catch (err) {
      console.log("Error", err);
      setLoading(false);
    }
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      if (values.search === "All Data") {
        await fetchVenues();
        setSearchflag(true);
        setOpen(false);
      } else {
        const searchdatas = await dataAxios.post("venues/search-results", {
          city: values.search,
          primary_venue_type: values.venuetype,
          limit: itemsPerPage,
          page_number: page,
        });
        const searchdatavalues = searchdatas?.data;
        const allData = searchdatas;
        setRecord(allData);
        setsearchvenues(searchdatavalues?.data?.reverse());
        setSearchflag(false);
        setOpen(false);
      }
    } catch (err) {
      console.error("Error occurred while posting form data:", err);
    } finally {
      setSubmitting(false);
    }
  };

  useEffect(() => {
    fetchVenues();
  }, []);

  const fetchSearch = async () => {
    try {
      const getOneAxios = await axiosData.get(`/general/search`);
      const data = getOneAxios?.data;
      if (data) {
        const citiesWithAllData = ["All Data", ...data.city];
        setCity(citiesWithAllData);
        setVenueType(data.venue_type);
      }
      return data;
    } catch (err) {
      console.log("Error", err);
    }
  };

  useEffect(() => {
    fetchSearch();
  }, []);

  const dataAxios = useAxios();

  const validationSchema = Yup.object().shape({
    search: Yup.string().required("City is required"),
    venuetype: Yup.string(),
  });

  // const SearchContinues = async () => {
  //   try {
  //     const searchdatas = await dataAxios.post("venues/search-results", {
  //       city: search,
  //       primary_venue_type: venuetype,
  //       limit: itemsPerPage,
  //       page_number: page,
  //     });
  //     const searchdatavalues = searchdatas?.data;
  //     setsearchvenues(searchdatavalues?.data?.reverse());
  //     setSearchflag(false);
  //     console.log("Response from API:", searchdatas.data);
  //   } catch (err) {
  //     console.error("Error occurred while posting form data:", err);
  //   }
  // }
  const renderSkeletonBox = (index) => (
    <div key={index} className="venues-box">
      <div
        className="img-venue"
        style={{ backgroundColor: "#ccc", height: "230px" }}
      ></div>
      <div className="venue-content">
        <div
          style={{
            backgroundColor: "#ccc",
            height: "20px",
            marginBottom: "10px",
          }}
        ></div>
        <div className="venue-para1">
          <span className="lastword-venue">
            <div
              style={{ backgroundColor: "#ccc", height: "19px", width: "50px" }}
            ></div>
          </span>
        </div>
        <span className="lastword-venue">
          <div
            style={{ backgroundColor: "#ccc", height: "20px", width: "100px" }}
          ></div>
        </span>
        <div className="venue-para2">
          <div
            style={{ backgroundColor: "#ccc", height: "20px", width: "150px" }}
          ></div>
        </div>
        <div className="bage-name-venues-loding">
          <div
            style={{ backgroundColor: "#ccc", height: "20px", width: "100px" }}
          ></div>
        </div>
      </div>
      <Divider className="venue-divider" />
      <div className="venue-para3">
        <div
          style={{ backgroundColor: "#ccc", height: "20px", width: "100px" }}
        ></div>
      </div>
    </div>
  );

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <BottomRightForm></BottomRightForm>
      <div className="hero-img-block-2">
        <img src={heroimg} alt="heroimg" />

        <div className="overlay"></div>
        <div className="search-content">
          <p className="homePageSearchContent">
            Because the best moments in life deserve the best
          </p>
          <Formik
            initialValues={{ search: "", venuetype: "" }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting, values, setFieldValue }) => (
              <Form className="search-input">
                <Field name="search">
                  {({ field, meta }) => (
                    <div className="input-field-search">
                      <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={city}
                        disableClearable
                        size="small"
                        value={values.search}
                        onChange={(event, newValue) => {
                          setFieldValue("search", newValue);
                        }}
                        sx={{
                          "& fieldset": { border: "none" },
                          width: 250,
                          zIndex: 1000,
                        }}
                        renderInput={(params) => (
                          <>
                            <TextField
                              {...params}
                              {...field}
                              placeholder="Select city"
                              error={meta.touched && !!meta.error}
                              InputProps={{
                                ...params.InputProps,
                                style: placeholderStyle,
                                startAdornment: (
                                  <InputAdornment
                                    position="start"
                                    className="img-size-search"
                                  >
                                    {/* Your location icon */}
                                  </InputAdornment>
                                ),
                              }}
                            />
                            <ErrorMessage
                              name="search"
                              // component="div"
                              className="error"
                            />
                          </>
                        )}
                      />
                    </div>
                  )}
                </Field>
                <Field name="venuetype">
                  {({ field, meta }) => (
                    <div className="input-field-search">
                      <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        disableClearable
                        size="small"
                        options={venueType}
                        value={values.venuetype}
                        onChange={(event, newValue) => {
                          setFieldValue("venuetype", newValue);
                        }}
                        sx={{ "& fieldset": { border: "none" }, width: 250 }}
                        renderInput={(params) => (
                          <>
                            <TextField
                              {...params}
                              {...field}
                              placeholder="Venues type"
                              error={meta.touched && !!meta.error}
                              InputProps={{
                                ...params.InputProps,
                                style: placeholderStyle,
                              }}
                            />
                            {/* <ErrorMessage
                                name="venuetype"
                                component="div"
                                className="error"
                              /> */}
                          </>
                        )}
                      />
                    </div>
                  )}
                </Field>
                <Button
                  className="search-btn"
                  type="submit"
                  disabled={isSubmitting}
                >
                  SEARCH
                </Button>
                <div className="btns-for-services">
                  {/* Buttons for services */}
                </div>
              </Form>
            )}
          </Formik>
        </div>
        <Modal open={open} onClose={handleClose}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Formik
              initialValues={{ search: "", venuetype: "" }}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ isSubmitting, values, setFieldValue }) => (
                <Form>
                  <Field name="search">
                    {({ field, meta }) => (
                      <div className="input-field-search">
                        <Autocomplete
                          disablePortal
                          id="combo-box-demo"
                          options={city}
                          disableClearable
                          size="small"
                          value={values.search}
                          onChange={(event, newValue) => {
                            setFieldValue("search", newValue);
                          }}
                          sx={{
                            "& fieldset": { border: "none" },
                            width: 250,
                            zIndex: 1000,
                          }}
                          renderInput={(params) => (
                            <>
                              <TextField
                                {...params}
                                {...field}
                                placeholder="Select city"
                                error={meta.touched && !!meta.error}
                                InputProps={{
                                  ...params.InputProps,
                                  style: placeholderStyle,
                                }}
                              />
                              <div style={{ textAlign: "center" }}>
                                <ErrorMessage name="search" className="error" />
                              </div>
                            </>
                          )}
                        />
                      </div>
                    )}
                  </Field>
                  <Field name="venuetype">
                    {({ field, meta }) => (
                      <div className="input-field-search">
                        <Autocomplete
                          disablePortal
                          id="combo-box-demo"
                          disableClearable
                          size="small"
                          options={venueType}
                          value={values.venuetype}
                          onChange={(event, newValue) => {
                            setFieldValue("venuetype", newValue);
                          }}
                          sx={{
                            "& fieldset": { border: "none" },
                            width: 250,
                          }}
                          renderInput={(params) => (
                            <>
                              <TextField
                                {...params}
                                {...field}
                                placeholder="Venues type"
                                error={meta.touched && !!meta.error}
                                InputProps={{
                                  ...params.InputProps,
                                  style: placeholderStyle,
                                }}
                              />
                            </>
                          )}
                        />
                      </div>
                    )}
                  </Field>
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <Button
                      className="search-btn"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      SEARCH
                    </Button>
                  </Box>
                </Form>
              )}
            </Formik>
          </Box>
        </Modal>
        <div className="mobile-search" onClick={handleOpen}>
          <div className="icon-mobile-search">
            <SearchIcon sx={{ color: "#fff" }} />
          </div>
          <div>Search</div>
          <div>
            <Lottie animationData={animationData} style={{ width: "30px" }} />
          </div>
        </div>
      </div>

      <div id="Listings-block" className="Listings-block">
        {/* <div className="common-header-title">
          <h3>Top Listings</h3>
        </div> */}

        <div id="searchvales1">
          <Container>
            {loading ? (
              <div className="split-venues-box">
                {[1, 2, 3].map((index) => renderSkeletonBox(index))}
              </div>
            ) : searchflag ? (
              topVenueDatas && topVenueDatas.length > 0 ? (
                <>
                  {" "}
                  <div className="common-header-title">
                    <h3>Top Listings</h3>
                  </div>
                  <div className="split-venues-box">
                    {topVenueDatas &&
                      topVenueDatas.map((data, index) => (
                        <div
                          onClick={() => {
                            navigate(
                              "/" +
                                ROUTINGDATA.VENUESPAGEDETAILSID +
                                `/${data._id}`,
                              {
                                state: {
                                  id: data._id,
                                  getImage:
                                    index === 3
                                      ? topListingImgs[0]
                                      : topListingImgs[index],
                                },
                              }
                            );
                            window.scrollTo({ top: 0, behavior: "smooth" });
                          }}
                          className="venues-box"
                        >
                          <div className="img-venue">
                            <img src={data.thumbnailUrl} alt="thumbnail" />
                          </div>
                          <div className="venue-content">
                            <Tooltip title={data.brand_name} arrow>
                              <h3>{data.brand_name}</h3>
                            </Tooltip>
                            <div className="venue-para1">
                              <span className="lastword-venue">
                                <StarRateRoundedIcon
                                  className="star-icon"
                                  sx={{
                                    fontSize: 19,
                                    color: "#F5AA35",
                                    marginRight: "5px",
                                  }}
                                />
                                <text>
                                  4.5<span>(10)</span>
                                </text>
                              </span>
                            </div>
                            <span className="lastword-venue ">
                              <LocationOnIcon />
                              <span className="location">
                                &nbsp;{data.city}
                              </span>
                            </span>
                            <div className="venue-para2">
                              <Groups2RoundedIcon
                                sx={{
                                  fontSize: 23,
                                  color: "#3F3F3F",
                                  marginRight: "5px",
                                }}
                              />
                              Capacity - {data.capacity_persons} guest.
                            </div>
                            <div className="bage-name-venues">
                              <text>
                                {" "}
                                {data.primary_venue_type
                                  .toLowerCase()
                                  .includes("lawn")
                                  ? "Lawn"
                                  : data.primary_venue_type
                                      .toLowerCase()
                                      .includes("banquet")
                                  ? "Banquet"
                                  : data.primary_venue_type
                                      .toLowerCase()
                                      .includes("resort") ||
                                    data.primary_venue_type
                                      .toLowerCase()
                                      .includes("restaurant")
                                  ? "Resort"
                                  : "Party Hall"}
                              </text>
                            </div>
                          </div>
                          <Divider className="venue-divider" />
                          <div className="venue-para3">
                            &nbsp; From{" "}
                            <span className="price">₹{data.min_price_day}</span>
                            -
                            <span className="price">₹{data.max_price_day}</span>
                            &nbsp;
                            <Button
                              variant="contained"
                              style={{ backgroundColor: "orange" }}
                              className="know-more-btn"
                            >
                              KNOW MORE
                            </Button>
                          </div>
                        </div>
                      ))}
                  </div>
                </>
              ) : (
                <div className="no-records-found">
                  <img
                    src={notFound}
                    alt="not-found"
                    width="150px"
                    height="100px"
                  />
                  <Typography>No venues found.</Typography>
                </div>
              )
            ) : searchvenues && searchvenues.length > 0 ? (
              <>
                <div className="common-header-title">
                  <h3>Search Results</h3>
                </div>
                <div className="split-venues-box">
                  {searchvenues &&
                    searchvenues.map((data, index) => (
                      <div
                        className="venues-box"
                        onClick={() => {
                          navigate(
                            "/" +
                              ROUTINGDATA.VENUESPAGEDETAILSID +
                              `/${data._id}`,
                            {
                              state: {
                                id: data._id,
                                getImage:
                                  index === 3
                                    ? topListingImgs[0]
                                    : topListingImgs[index],
                              },
                            }
                          );
                          window.scrollTo({ top: 0, behavior: "smooth" });
                        }}
                      >
                        <div className="img-venue">
                          <img src={data.thumbnailUrl} alt="thumbnail" />
                        </div>
                        <div className="venue-content">
                          <Tooltip title={data.brand_name} arrow>
                            <h3>{data.brand_name}</h3>
                          </Tooltip>
                          <div className="venue-para1">
                            <span className="lastword-venue">
                              <StarRateRoundedIcon
                                className="star-icon"
                                sx={{
                                  fontSize: 19,
                                  color: "#F5AA35",
                                  marginRight: "5px",
                                }}
                              />
                              <text>
                                4.5<span>(10)</span>
                              </text>
                            </span>
                          </div>
                          <span className="lastword-venue ">
                            <LocationOnIcon />
                            <span className="location">&nbsp;{data.city}</span>
                          </span>
                          <div className="venue-para2">
                            <Groups2RoundedIcon
                              sx={{
                                fontSize: 23,
                                color: "#3F3F3F",
                                marginRight: "5px",
                              }}
                            />
                            Capacity - {data.capacity_persons} guest.
                          </div>
                          <div className="bage-name-venues">
                            <text>
                              {" "}
                              {data.primary_venue_type
                                .toLowerCase()
                                .includes("lawn")
                                ? "Lawn"
                                : data.primary_venue_type
                                    .toLowerCase()
                                    .includes("banquet")
                                ? "Banquet"
                                : data.primary_venue_type
                                    .toLowerCase()
                                    .includes("resort") ||
                                  data.primary_venue_type
                                    .toLowerCase()
                                    .includes("restaurant")
                                ? "Resort"
                                : "Party Hall"}
                            </text>
                          </div>
                        </div>
                        <Divider className="venue-divider" />
                        <div className="venue-para3">
                          &nbsp; From{" "}
                          <span className="price">₹{data.min_price_day}</span>-
                          <span className="price">₹{data.max_price_day}</span>
                          &nbsp;
                          <Button
                            variant="contained"
                            style={{ backgroundColor: "orange" }}
                            className="know-more-btn"
                          >
                            KNOW MORE
                          </Button>
                        </div>
                      </div>
                    ))}
                </div>
              </>
            ) : (
              <div className="no-records-found">
                <img
                  src={notFound}
                  alt="not-found"
                  width="150px"
                  height="100px"
                />
                <Typography>No search results found.</Typography>
              </div>
            )}
          </Container>
        </div>
      </div>
      <div className="pagination-part">
        {(searchflag && topVenueDatas && topVenueDatas.length > 0) ||
        (!searchflag && searchvenues && searchvenues.length > 0) ? (
          <Pagination
            count={Math.ceil(
              (searchflag
                ? record_detials?.data?.total_record
                : record?.data?.total_record) / itemsPerPage
            )}
            page={page}
            onChange={handlePageChange}
            sx={{
              "& .MuiPaginationItem-root:hover": {
                backgroundColor: "#F5AA35",
                color: "#fff",
              },
              "& .MuiPaginationItem-root.Mui-selected": {
                backgroundColor: "#F5AA35",
                color: "#fff",
              },
              "& .MuiPaginationItem-root": {
                color: "#3B2400",
              },
            }}
          />
        ) : null}
      </div>
    </div>
  );
  // } else {
  //   return (
  //     <div
  //       style={{
  //         height: "100vh",
  //         display: "grid",
  //         placeItems: "center",
  //         // transform: "translate(0,50vh)",
  //       }}
  //     >
  //       <div class="dots"></div>
  //     </div>
  //   );
  // }
};

export default Venuespage;
