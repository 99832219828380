import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import InfoIcon from "@mui/icons-material/Info";
import PhotoSizeSelectActualIcon from "@mui/icons-material/PhotoSizeSelectActual";

import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import { Container } from "@mui/material";
import VenuesImage from "../Images/venues.jpeg";
import VenuesImages from "../Images/venues.img.jpg";
import { alpha } from "@mui/material/styles";
import Menu from "@mui/material/Menu";
import DeleteIcon from "@mui/icons-material/Delete";
import Tooltip from "@mui/material/Tooltip";
import "./Venuespages.css";
import { useNavigate } from "react-router-dom";
import { ROUTINGDATA } from "../ROUTINGDATA";
import useAxios from "../../useAxios";
import { useEffect } from "react";
import EditIcon from "@mui/icons-material/Edit";
import Breadcrumb from "../BreadCrumb";
const drawerWidth = 240;

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));
// Set initial state to true to keep the drawer always open

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
  backgroundColor: open ? "#FFFFFF" : "#FFFFFF", //
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function MiniDrawer() {
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const [dataGet, setDataGet] = React.useState([]);
  const [venueimages, setvenueimages] = React.useState([]);
  const navigate = useNavigate();

  const userEmail = localStorage.getItem("userEmail");

  const axiosData = useAxios();
  const venuesData = async () => {
    axiosData.get(`venues/email/${userEmail}`).then((response) => {
      setDataGet(response.data);
    });
  };
  useEffect(() => {
    venuesData();
  }, []);

  const handledelete = async (id) => {
    await axiosData.delete(`venues/${id}`);
    alert("Your Venue has Successfully Deleted");
  };

  return (
    <>
      <div className="navigation-vendors">
        <div className="navigation-btn-vendors-dashboard">
          <div>
            <text style={{ color: "#F5AA35" }}>
              <InfoIcon />
              Venue Listing
            </text>
          </div>
          <div
            className="addPic"
            onClick={() => {
              navigate(`${ROUTINGDATA.LAND}${ROUTINGDATA.PLANNER}`, {
                state: dataGet,
              });
              window.scrollTo({ top: 0, behavior: "smooth" });
            }}
          >
            <text>
              <PhotoSizeSelectActualIcon />
              Add/Edit PortFolio
            </text>
          </div>
        </div>
      </div>
      <div className="form-for-vendors">
        <Box
          sx={{ marginLeft: "250px", marginTop: "150px", marginRight: "80px" }}
        >
          <Box
            sx={{
              marginTop: 12,
              marginLeft: 14,
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography
              variant="h3"
              sx={{
                padding: "5px",
                fontFamily: "Poppins-Regular",
                color: "#5F3F3B",
                fontSize: "14px",
                fontWeight: "bold",
              }}
            >
              Venues
            </Typography>
            <Button
              style={{
                borderRadius: 20,
                borderColor: "#F5AA35",
                color: "black",
                justifyContent: "space-evenly",
                marginLeft: 10,
              }}
              variant="outlined"
              onClick={() => {
                navigate(`${ROUTINGDATA.LAND}${ROUTINGDATA.VENUESDASHBOARD}`);
                window.scrollTo({ top: 0, behavior: "smooth" });
              }}
            >
              +Add venues
            </Button>
          </Box>

          <Box sx={{ marginLeft: 12 }}>
            <Table>
              <TableHead>
                <TableRow
                  style={{
                    marginTop: "1.9%",
                    borderBottom: "3px solid #5F3F3B",
                  }}
                >
                  <TableCell align="center" sx={{ marginLeft: 3 }}>
                    Thumbnails
                  </TableCell>
                  <TableCell align="center">Venue Name</TableCell>
                  <TableCell align="center">Location</TableCell>
                  <TableCell align="center">Venue Type</TableCell>
                  <TableCell align="center"> Address</TableCell>
                  <TableCell align="center">Price</TableCell>
                  <TableCell align="center">Actions</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {dataGet.length > 0 ? (
                  dataGet.map((row, index) => (
                    <TableRow key={index}>
                      {" "}
                      {/* Provide a unique key for each row */}
                      <TableCell align="center">
                        <img
                          style={{ width: 100, marginLeft: 30 }}
                          alt="img"
                          src={row.thumbnailUrl}
                        />
                      </TableCell>
                      <TableCell align="center">{row.brand_name}</TableCell>
                      <TableCell align="center">{row.city}</TableCell>
                      <TableCell align="center">
                        {row.primary_venue_type}
                      </TableCell>
                      <TableCell align="center">{row.address}</TableCell>
                      <TableCell
                        align="center"
                        style={{ color: "orange", fontSize: 20 }}
                      >
                        &#x20B9;{row.min_price_day}-&#x20B9;{row.max_price_day}
                      </TableCell>
                      <TableCell align="center">
                        <Tooltip title="Delete Venue">
                          <IconButton
                            onClick={() => {
                              handledelete(row._id);
                            }}
                          >
                            <DeleteIcon style={{ marginTop: 30 }} />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                      <TableCell align="center">
                        <Tooltip title="Edit Your Details">
                          <IconButton
                            onClick={() => {
                              navigate(
                                `${ROUTINGDATA.LAND}${ROUTINGDATA.EDITS}`,
                                {
                                  state: {
                                    id: row._id,
                                    VenuesName: row.brand_name,
                                  },
                                }
                              );
                            }}
                          >
                            <EditIcon style={{ marginTop: 30 }} />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={7} align="center">
                      <p>Need to add venue</p>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </Box>
        </Box>
      </div>
    </>
  );
}
