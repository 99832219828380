import { Box, Button, Typography } from "@mui/material";
import { useState, useRef, useEffect } from "react";
import { useFormik } from "formik";
import { useLocation } from "react-router-dom";
import useAxios from "../../useAxios";
import "./Addphotos.css";
import { uploadImage } from "../../HelperFunction";

export default function MiniDrawer() {
  const [uploadPreviewImages, setUploadPreviewImages] = useState([]);
  const [videoPreviews, setVideoPreviews] = useState([]);
  const [existingPhotos, setExistingPhotos] = useState([]);
  const [existingVideos, setExistingVideos] = useState([]);
  const [activeOption, setActiveOption] = useState("photos"); // Track active option
  const fileInputRef = useRef(null);
  const location = useLocation();
  const axios = useAxios();

  useEffect(() => {
    const fetchExistingMedia = async () => {
      try {
        const response = await axios.get(`venues/${location.state.id}`);
        setExistingPhotos(response.data.imageUrl || []);
        setExistingVideos(response.data.videoUrl || []);
      } catch (error) {
        console.error("Error fetching existing photos and videos:", error);
      }
    };

    fetchExistingMedia();
  }, [location.state.id]);
  const formik = useFormik({
    initialValues: {
      localProfileUpload1: [],
      venuevideo: [],
    },
    // onSubmit: (values) => {
    //   console.log("the edited image and video data: ", values);
    //   const formData = new FormData();
    //   values.localProfileUpload1.forEach((video) => {
    //     formData.append("localProfileUpload1", video);
    //   });
    //   values.venuevideo.forEach((video) => {
    //     formData.append("venuevideo", video);
    //   });
    //   formData.append("venue_id", location.state.id);
    //   formData.append("vendor_id", location.state.vendor_id);

    //   axios
    //     .post(venues/images, formData, {
    //       headers: { "Content-Type": "multipart/form-data" },

    //     })

    //     .then((res) => {
    //       alert("Photos and Videos Successfully Uploaded");
    //       formik.resetForm();
    //       setUploadPreviewImages([]);
    //       setVideoPreviews([]);
    //     })
    //     .catch((error) => {
    //       console.error("Error uploading photos and videos:", error);
    //       // Handle error, show an error message, etc.
    //     });
    // },

    onSubmit: async (values) => {
      let publicUrl = [];
      let imageApprovalData = [];

      if (
        Array.isArray(values.localProfileUpload1) &&
        values.localProfileUpload1.length > 0
      ) {
        const existingUrls = values.localProfileUpload1.filter(
          (image) => typeof image === "string"
        );
        const newImages = values.localProfileUpload1.filter(
          (image) => typeof image !== "string"
        );

        const newUrls = await Promise.all(
          newImages.map(
            async (image) => await uploadImage("portfolio", image, true)
          )
        );

        publicUrl = [...existingUrls, ...newUrls];
        imageApprovalData = publicUrl.map((url) => ({
          url: url,
          approvalStatus: "P",
          reason: "",
        }));

        try {
          const response = await axios.put(
            `venues/id/${location.state.id}`,
            { imageUrl: imageApprovalData },
            { headers: { "Content-Type": "application/json" } } // Changed Content-Type to application/json
          );
          alert("Photos Successfully Uploaded");
        } catch (error) {
          console.error("Error uploading photos:", error);
        }
      }

      let videoPublicUrl = [];
      let videoApprovalData = [];
      if (Array.isArray(values.venuevideo) && values.venuevideo.length > 0) {
        const existingUrls = values.venuevideo.filter(
          (video) => typeof video === "string"
        );
        const newVideos = values.venuevideo.filter(
          (video) => typeof video !== "string"
        );
        const newUrls = await Promise.all(
          newVideos.map(
            async (video) => await uploadImage("videos", video, true)
          )
        );
        videoPublicUrl = [...existingUrls, ...newUrls];
        videoApprovalData = videoPublicUrl.map((url) => ({
          url: url,
          approvalStatus: "P",
          reason: "",
        }));
        try {
          const response = await axios.put(
            `venues/id/${location.state.id}`,
            { videoUrl: videoApprovalData },
            { headers: { "Content-Type": "application/json" } } // Changed Content-Type to application/json
          );
          alert("Videos Successfully Uploaded");
        } catch (error) {
          console.error("Error uploading photos:", error);
        }
      }
    },
  });

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    const totalImages = uploadPreviewImages.length + files.length;

    if (totalImages > 10) {
      alert("You can upload a maximum of 10 images.");
      return;
    }

    const newImages = [];
    const newPreviewImages = [];

    files.forEach((file) => {
      if (file.size > 10 * 1024 * 1024) {
        // 5 MB
        alert("Maximum file size allowed for an image is 5 MB.");
        return;
      }

      const previewImage = URL.createObjectURL(file);
      newPreviewImages.push(previewImage);
      newImages.push(file);
    });

    setUploadPreviewImages((prevImages) => [
      ...prevImages,
      ...newPreviewImages,
    ]);
    formik.setFieldValue("localProfileUpload1", [
      ...formik.values.localProfileUpload1,
      ...newImages,
    ]);
  };

  const handleVideoChange = (event) => {
    const files = Array.from(event.target.files);
    const totalVideos = videoPreviews.length + files.length;

    if (totalVideos > 3) {
      alert("You can upload a maximum of 3 videos.");
      return;
    }

    const newVideos = [];
    const newVideoPreviews = [];

    files.forEach((file) => {
      if (file.size > 30 * 1024 * 1024) {
        // 10 MB
        alert("Maximum file size allowed for a video is 10 MB.");
        return;
      }

      const videoPreviewUrl = URL.createObjectURL(file);
      newVideoPreviews.push(videoPreviewUrl);
      newVideos.push(file);
    });

    setVideoPreviews((prevPreviews) => [...prevPreviews, ...newVideoPreviews]);
    formik.setFieldValue("venuevideo", [
      ...formik.values.venuevideo,
      ...newVideos,
    ]);
  };

  const handleDeleteImage = (index) => {
    const updatedImages = [...uploadPreviewImages];
    updatedImages.splice(index, 1);
    setUploadPreviewImages(updatedImages);
    const updatedFiles = [...formik.values.localProfileUpload1];
    updatedFiles.splice(index, 1);
    formik.setFieldValue("localProfileUpload1", updatedFiles);
  };

  const handleDeleteVideo = (index) => {
    const updatedPreviews = [...videoPreviews];
    updatedPreviews.splice(index, 1);
    setVideoPreviews(updatedPreviews);
    const updatedFiles = [...formik.values.venuevideo];
    updatedFiles.splice(index, 1);
    formik.setFieldValue("venuevideos", updatedFiles);
  };

  const handleOptionChange = (option) => {
    setActiveOption(option);
  };

  return (
    <Box>
      <Box component="main" sx={{ flexGrow: 1, p: 3, marginLeft: 10 }}>
        <h3>
          Adding {activeOption === "photos" ? "Photos" : "Videos"} To{" "}
          {location.state.VenuesName}
        </h3>

        <div className="buttouns">
          <Button
            variant={activeOption === "photos" ? "contained" : "outlined"}
            onClick={() => handleOptionChange("photos")}
            sx={{
              backgroundColor: activeOption === "photos" ? "orange" : "white",
              color: activeOption === "photos" ? "white" : "orange",
              border: "1px solid orange",
              "&:hover": {
                backgroundColor:
                  activeOption === "photos" ? "orange" : "#FFB74D",
                color: "white",
                borderColor: "orange",
              },
            }}
          >
            Add Photos
          </Button>
          <Button
            variant={activeOption === "videos" ? "contained" : "outlined"}
            onClick={() => handleOptionChange("videos")}
            sx={{
              backgroundColor: activeOption === "videos" ? "orange" : "white",
              color: activeOption === "videos" ? "white" : "orange",
              border: "1px solid orange",
              "&:hover": {
                backgroundColor:
                  activeOption === "videos" ? "orange" : "#FFB74D",
                color: "white",
                borderColor: "orange",
              },
            }}
          >
            Add Videos
          </Button>
        </div>
        <form onSubmit={formik.handleSubmit}>
          <Box display="flex" justifyContent="space-between">
            <Box>
              {activeOption === "photos" && (
                <>
                  <div
                    className="photo-container"
                    onClick={() => fileInputRef.current.click()}
                  >
                    <h4 className="addpicses">+ Add photo</h4>
                    <input
                      type="file"
                      accept="image/*"
                      style={{ display: "none" }}
                      ref={fileInputRef}
                      onChange={handleFileChange}
                      multiple
                    />
                  </div>

                  {/* Show newly uploaded preview images */}
                  <div className="photo-gallery">
                    {uploadPreviewImages.map((previewImage, index) => (
                      <div key={index} style={{ position: "relative" }}>
                        <img
                          src={previewImage}
                          alt="Preview"
                          className="photo" // Apply the new class for styling
                        />
                        <button
                          type="button"
                          onClick={() => handleDeleteImage(index)}
                          style={{
                            position: "absolute",
                            top: "5px",
                            right: "5px",
                            color: "orange",
                          }}
                        >
                          Delete
                        </button>
                      </div>
                    ))}
                  </div>
                </>
              )}

              {activeOption === "videos" && (
                <>
                  {videoPreviews.map((previewUrl, index) => (
                    <div key={index}>
                      <video controls width="300">
                        <source src={previewUrl} type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                      <button
                        type="button"
                        onClick={() => handleDeleteVideo(index)}
                      >
                        Delete
                      </button>
                    </div>
                  ))}
                  <div
                    className="photo-container"
                    onClick={() => fileInputRef.current.click()}
                  >
                    <h4 className="addpicses">+ Add video</h4>
                    <input
                      type="file"
                      accept="video/*"
                      style={{ display: "none" }}
                      ref={fileInputRef}
                      onChange={handleVideoChange}
                      multiple
                    />
                  </div>
                </>
              )}
            </Box>

            {/* Right Side for Existing Media */}
            <Box width="40%" marginLeft={5}>
              {activeOption === "photos" && (
                <div>
                  <Typography variant="h6">Existing Photos</Typography>
                  <div className="photo-gallery">
                    {existingPhotos.length > 0 ? (
                      existingPhotos.map((photo, index) => (
                        <img
                          key={index}
                          src={photo.url}
                          alt={`Existing Photo ${index}`}
                          className="photo"
                        />
                      ))
                    ) : (
                      <p>No existing photos</p>
                    )}
                  </div>
                </div>
              )}

              {activeOption === "videos" && (
                <div>
                  <Typography variant="h6">Existing Videos</Typography>
                  {existingVideos.length > 0 ? (
                    existingVideos.map((video, index) => (
                      <div key={index}>
                        <video
                          controls
                          width="100%"
                          style={{ marginBottom: "10px" }}
                        >
                          <source src={video.url} type="video/mp4" />
                          Your browser does not support the video tag.
                        </video>
                      </div>
                    ))
                  ) : (
                    <p>No existing videos</p>
                  )}
                </div>
              )}
            </Box>
          </Box>

          <div className="uploadBtn">
            <Button
              type="submit"
              color="primary"
              variant="outlined"
              onClick={formik.handleSubmit}
              sx={{
                backgroundColor: "orange",
                color: "white",
                border: "1px solid orange",
                "&:hover": {
                  backgroundColor: "#FFB74D",
                  color: "white",
                  borderColor: "#FFB74D",
                },
              }}
            >
              Upload
            </Button>
          </div>
        </form>

        <h4>{activeOption === "photos" ? "Photo" : "Video"} guidance</h4>
        <ul className="guidance">
          {activeOption === "photos" && (
            <>
              <li>Only upload images that are related to your work/industry</li>
              <li>Upload only your original work</li>
              <li>
                Upload images with a minimum width of 400 pixels & max. size of
                8MB
              </li>
            </>
          )}
          {activeOption === "videos" && (
            <>
              <li>Upload videos with common formats like MP4</li>
              <li>Maximum size for video uploads is 10MB</li>
            </>
          )}
        </ul>
      </Box>
    </Box>
  );
}
